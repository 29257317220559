<template>
  <div class="font-montserrat top">
    <div class="row py-24 d-flex justify-content-center px-12">
      <div class="col-md-8">
        <img src="../../assets/images/HealthHub/healthhubbanner.webp" alt="">
      </div>
    </div>

    <div class="container">
      <div class="row px-10 py-5 ">
        <div class="col-md-12"><h4>Select a package to purchase</h4></div>
        <div class="container">
      <div  class="col-md-12 text-right">
        <router-link v-if="islogin == true" to="/view-purchased-packs" class="btn oonpay-bg mb-5 text-white btn-sm">View Purchased Pack</router-link>
      </div>
     </div>
        <div
          class="col-md-3  m-2"
          style="border: 1px solid black; border-radius: 5px;"
        >
          <img
            src="../../assets/images/ambulance_emergency.png"
            width="100px"
            height="100px"
            alt=""
          />
          <p>Laboratory</p>
        </div>
        <div
          class="col-md-3  m-2"
          style="border: 1px solid black; border-radius: 5px;"
        >
          <img
            src="../../assets/images/ambulance_emergency.png"
            width="100px"
            height="100px"
            alt=""
          />
          <p>Vaccine</p>
        </div>
        <div
          class="col-md-3 m-2"
          style="border: 1px solid black; border-radius: 5px;"
        >
          <img
            src="../../assets/images/ambulance_emergency.png"
            width="100px"
            height="100px"
            alt=""
          />
          <p>Wellness</p>
        </div>
        <div
          class="col-md-3 m-2"
          style="border: 1px solid black; border-radius: 5px;"
        >
          <img
            src="../../assets/images/ambulance_emergency.png"
            width="100px"
            height="100px"
            alt=""
          />
          <p>Eye Check</p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row px-10 py-5 ">
        <div class="col-md-12">
          <h4>Select a category under Laboratory to purchase</h4>
        </div>
        <div
          class="col-md-3  m-2"
          style="border: 1px solid black; border-radius: 5px;"
        >
          <img
            src="../../assets/images/ambulance_emergency.png"
            width="100px"
            height="100px"
            alt=""
          />
          <p>Culture And Sensitive Test</p>
        </div>
        <div
          class="col-md-3  m-2"
          style="border: 1px solid black; border-radius: 5px;"
        >
          <img
            src="../../assets/images/ambulance_emergency.png"
            width="100px"
            height="100px"
            alt=""
          />
          <p>Blod Parasite</p>
        </div>
        <div
          class="col-md-3 m-2"
          style="border: 1px solid black; border-radius: 5px;"
        >
          <img
            src="../../assets/images/ambulance_emergency.png"
            width="100px"
            height="100px"
            alt=""
          />
          <p>Blood Type and Blood Disorders</p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row px-10 pb-5">
        <div class="col-md-6">
          <div class="form-group">
            <h6 for="purchase for">Purchase Pack For</h6>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="package_for"
                value="myself"
              />
              <label class="form-check-label" for="inlineRadio1">My Self</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                v-model="package_for"
                value="others"
              />
              <label class="form-check-label" for="inlineRadio2">Others</label>
            </div>
          </div>

          <div class="container px-4 pb-4" v-if="package_for == 'others'">
            <div class="form-group">
              <label for="fullname">Full Name</label>
              <input type="text" placeholder="full name" class="form-control" />
            </div>
            <div class="form-group">
              <label for="fullname">Phone Number</label>
              <input
                type="text"
                placeholder="phone number"
                class="form-control"
              />
            </div>
            <button class="btn btn-primary btn-block">
              Add Beneficiary
            </button>
          </div>
          <div class="form-group">
            <h6 for="purchase for">Package Location</h6>

            <button class="btn btn-outline-warning">
              Use Current GPS: Location
            </button>
          </div>
          <div class="form-group">
            <label for="select country">Select Country</label>
            <select class="form-control" name="country" id="">
              <option value="">Ghana</option>
            </select>
          </div>
          <div class="form-group">
            <label for="location">Search approximate package location</label>
            <input
              type="text"
              placeholder="Search approximate package location"
              class="form-control"
            />
          </div>

          <div class="form-group">
            <label for="street">Street address</label>
            <input
              type="text"
              placeholder="Street address"
              class="form-control"
            />
          </div>

          <button class="btn btn-primary btn-block">Search Package</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      islogin: false,
    }
  },
};
</script>

<style scoped>
.bg-color {
  background: #f7f4fd;
}

.oonpay-bg {
  background: #0c4369;
}

@media screen and (max-width: 720px) {
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
