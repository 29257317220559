<template>
    <div>
        <the-app-bar/>
        <healthhub-client/>
        <Footer/>
    </div>
</template>

<script>
import Footer from '../../../components/footer.vue'
import healthhubClient from '../../../components/HealthHub/healthhubClient.vue'
import TheAppBar from '../../../components/NavBar/TheAppBar.vue'
    export default {
  components: { healthhubClient, TheAppBar, Footer },
        
    }
</script>

<style lang="scss" scoped>

</style>